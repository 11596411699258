






























import { Component, Prop, Vue } from "vue-property-decorator";
import ArticleListItem from "@/components/shop/article/list/ArticleListItem.vue";
import { DealArticle } from "@/types/shop/article";

@Component({
    components: {
        ArticleListItem,
    },
})
export default class ShopDealListItem extends Vue {
    @Prop({ type: Number, required: true }) readonly id!: number;
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, required: true }) readonly description!: string;
    @Prop({ type: String, required: true }) readonly startTime!: string;
    @Prop({ type: String, required: true }) readonly endTime!: string;
    @Prop({ type: Array, required: true })
    readonly dealArticles!: DealArticle[];
}
