
















import { Component, Vue, Prop } from "vue-property-decorator";
import ShopDealListItem from "./ShopDealListItem.vue";
import { Deal } from "@/types/shop/article";

@Component({
    components: {
        ShopDealListItem,
    },
})
export default class ShopDealList extends Vue {
    @Prop({ type: Array, required: true }) readonly deals!: Deal[];
}
