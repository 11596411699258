

































import { Component, Prop, Vue } from "vue-property-decorator";
import { CarouselItem } from "@/types/carousel";

@Component
export default class Carousel extends Vue {
    @Prop({ type: Array, required: true })
    readonly items!: CarouselItem[];
}
