



































import { Component, Watch, Vue } from "vue-property-decorator";
import Carousel from "@/components/Carousel/Carousel.vue";
import ShopDealList from "@/components/shop/deal/ShopDealList.vue";
import ArticleRecommendationList from "@/components/shop/article/recommendation/ArticleRecommendationList.vue";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { CarouselItem } from "@/types/carousel";
import { Article, Deal } from "@/types/shop/article";

@Component({
    components: {
        Carousel,
        ArticleRecommendationList,
        ShopDealList,
    },
})
export default class MainPage extends Vue {
    private dealCarouselItems: CarouselItem[] = [];
    private recommendedArticles: Article[] = [];

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.fetchData();
    }

    /**
     * Function will be automatic called by Vue.js when locale was changed
     * Function calls fetchData
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Watch("$root.$i18n.locale")
    private onLocaleChanged() {
        this.fetchData();
    }

    /**
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchData() {
        try {
            let deals = [];
            [this.recommendedArticles, deals] = await Promise.all([
                this.fetchRecommendedArticles(),
                this.fetchDeals(),
            ]);

            this.dealCarouselItems = deals.flatMap((d) => {
                return d.articles.map((a) => ({
                    imageSrc: a.article.images[0].src,
                    heading:
                        d.descriptions.length > 0
                            ? d.descriptions[0].title
                            : "",
                    subheading:
                        d.descriptions.length > 0
                            ? d.descriptions[0].description
                            : "",
                    button: {
                        content: this.$t("shop.deal.goToArticle").toString(),
                        clicked: () =>
                            this.navigateToArticleDetails(a.article.id),
                    },
                }));
            });
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Navigates to article details page
     *
     * @param articleId article id
     *
     * @author Kevin Danne <danne@skiba-procomputer>
     */
    private navigateToArticleDetails(articleId: number) {
        this.$router.push({
            name: "shopArticleDetails",
            params: {
                id: articleId.toString(),
            },
        });
    }

    /**
     * Fetches recommended articles
     *
     * @returns Promise<Article[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchRecommendedArticles(): Promise<Article[]> {
        // TODO change url when backend has implemented recommended articles
        const response = (await axios.get<APIResponse<Article[]>>("/articles/"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches deals
     *
     * @returns Promise<Deal[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchDeals(): Promise<Deal[]> {
        const response = (
            await axios.get<APIResponse<Deal[]>>("/article/deals")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
