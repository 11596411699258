






































import { Component, Vue, Prop } from "vue-property-decorator";
import ArticleRecommendationListItem from "./ArticleRecommendationListItem.vue";
import { Article } from "@/types/shop/article";
import CartModule from "@/store/modules/cart";
import AlertModule, { AlertType } from "@/store/modules/alert";

@Component({
    components: {
        ArticleRecommendationListItem,
    },
})
export default class ArticleRecommendationList extends Vue {
    @Prop({ type: Array, required: true }) readonly articles!: Article[];

    private addArticleToCart(article: Article, quantity: number) {
        CartModule.addArticle({
            commissionId: -1,
            commissionName: null,
            article,
            quantity,
        })
            .then(() => {
                this.$router.push({
                    name: "shoppingCart",
                });
            })
            .catch((err) => {
                const errorMessage =
                    err instanceof Error ? err.message : (err as string);

                AlertModule.showAlert({
                    type: AlertType.ERROR,
                    message: errorMessage,
                });
            });
    }
}
