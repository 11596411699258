












































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Image } from "@/types/image";
import { ArticlePrice } from "@/types/shop/article";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";

@Component({
    components: {
        ArticlePriceLabel,
    },
})
export default class ArticleRecommendationListItem extends Vue {
    @Prop({ type: Number, required: true }) readonly id!: number;
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, required: true }) readonly description!: string;
    @Prop({ type: Object, required: true }) readonly image!: Image;
    @Prop({ type: Object, required: true }) readonly price!: ArticlePrice;
    @Prop({ type: Number, default: undefined }) readonly dealPrice!:
        | number
        | undefined;

    private quantity = 1;
}
